import Router from "./Router";
import {Nav} from 'react-bootstrap';

function App() {
  return (
    <div className="App" class="container bg-lightgray">
      <div class="row rounded-pill bg-secondary fs-3" style={{marginTop:10,marginBottom:10}}>
        <Nav
        activeKey="/"
        >
        <Nav.Item>
          <Nav.Link 
          href="/"
          style={{marginLeft:20,color:"black"}} 
          >Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/launcherPage" style={{color:"black"}}>런처</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/settingPage" style={{color:"black"}}>설정 파일</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/utilPage" style={{color:"black"}}>유틸리티</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/osPage" style={{color:"black"}}>OS</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/nasPage" style={{color:"black"}}>NAS</Nav.Link>
        </Nav.Item>
        </Nav>    
      </div>
      <div class="row">
        <Router />
      </div>
    </div>
  );
}

export default App;
